import ActionHandler from '@/js/components/forms/actionHandler';
import { getTranslation } from '@/js/utils/translations';

export default class FormValidation extends ActionHandler {
  /**
   * @param formEl {HTMLFormElement}
   * @param mount {boolean}
   */
  constructor(formEl, mount = true) {
    super();
    this.dom = {
      formEl,
      fields: [],
      message: formEl.querySelector('.js-message'),
      loaderEl: document.getElementById('c-loader'),
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
    };

    this.data = {
      invalidClass: 'is-invalid',
      message: {
        text: null,
        class: null,
        type: null,
        scroll: true,
      },
      formValidationClass: 'was-validated',
      resetFormOnSuccess: true,
      errors: null,
      values: null,
      files: null,
    };

    this.events = {
      onFormSubmit: this.onFormSubmit.bind(this),
    };

    this.customEvents = {
      success: 'success',
      error: 'error',
    };

    if (mount) {
      this.mount();
    }
  }

  mount() {
    if (this.dom.formEl) {
      this.dom.formEl.addEventListener('submit', this.events.onFormSubmit);
    }

    if (this.dom.loaderEl) {
      this.addLoader(this.dom.loaderEl);
    }
  }

  /**
   * form has been submitted
   * @param e {SubmitEvent}
   */
  onFormSubmit(e) {
    // stay on page
    e.preventDefault();

    // reset message state
    this.resetMessage();

    // set fields
    this.dom.fields = [...this.dom.formEl.querySelectorAll('[name]:not([type="hidden"])')];

    // set formData
    const formData = new FormData(this.dom.formEl);

    // Add submitter
    if (e.submitter?.name && e.submitter?.value) {
      const { name, value } = e.submitter;
      formData.append(name, value);
    }

    this.sendFormData({ url: this.props.url, formData });
  }

  /**
   * @param response {Object}
   */
  onRequestSuccess(response) {
    this.data.errors = response.data.errors || [];
    this.data.values = response.data.values || {};
    this.data.files = response.data.files || {};
    this.parseMessage(response.data.message);
    this.setMessage();

    if (this.hasErrors()) {
      // form has errors
      this.handleErrors();
    } else {
      // form was submitted successfully
      this.handleSuccess();
    }

    super.onRequestSuccess(response);
  }

  onRequestError(error) {
    super.onRequestError(error);

    this.parseMessage({
      type: 'error',
      text: getTranslation('formOnRequestError'),
    }).setMessage();
  }

  hasErrors() {
    if (this.data.errors && Object.keys(this.data.errors).length > 0) {
      return true;
    }

    return this.data.message.type === 'error';
  }

  /**
   * parse message object
   * @param messageObj {Object}
   */
  parseMessage(messageObj) {
    switch (messageObj?.type) {
      case 'error':
        this.data.message.class = 'alert-danger';
        break;
      case 'success':
        this.data.message.class = 'alert-success';
        break;
      default:
        this.data.message.class = null;
        break;
    }

    this.data.message.text = messageObj?.text;
    this.data.message.type = messageObj?.type;

    return this;
  }

  // show message
  setMessage() {
    if (!this.dom.message) return;

    // if we have no message, exit fn
    if (!this.data.message.text) return;

    // apply classes
    if (this.data.message.class) {
      this.dom.message.classList.add(this.data.message.class);
    }

    // apply text
    this.setMessageText(this.data.message.text);

    // show message
    this.dom.message.classList.remove('d-none');

    // scroll to message
    if (this.data.message.scroll) {
      this.dom.message.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // empty message and hide it
  resetMessage() {
    // if we have no message element, exit fn
    if (!this.dom.message) return;

    // reset class
    this.dom.message.classList.add('d-none');
    if (this.data.message.class) {
      this.dom.message.classList.remove(this.data.message.class);
    }

    // reset text
    this.setMessageText('');
  }

  setMessageText(text) {
    this.dom.message.textContent = text;
  }

  // form was submitted successfully
  handleSuccess() {
    // hide validation states
    this.dom.formEl.classList.remove(this.data.formValidationClass);

    // reset form
    if (this.data.resetFormOnSuccess) {
      this.dom.formEl.reset();
    }

    // Set the defined values
    if (this.data.values) {
      Object.entries(this.data.values).forEach(([key, value]) => {
        const field = this.dom.formEl.querySelector(`[name="${key}"]`);

        if (field) {
          field.value = value;
        }
      });
    }

    this.dom.formEl.dispatchEvent(new CustomEvent(this.customEvents.success, {
      detail: {
        message: this.data.message,
        values: this.data.values,
        files: this.data.files,
      },
    }));
  }

  // form has errors
  handleErrors() {
    this.dom.fields.forEach((field) => {
      const error = Object.entries(this.data.errors)
        .find(([key]) => field.name === key);

      if (!error) {
        if (field.validity.customError) {
          // Remove custom error if is set
          field.setCustomValidity('');
        }

        return;
      }

      // Get the value
      const [, value] = error;

      // set custom error
      field.setCustomValidity(value.toString());

      const invalidFeedback = field.parentNode.querySelector('.invalid-feedback');

      // set error
      if (invalidFeedback) {
        invalidFeedback.innerHTML = value.toString();
      }
    });

    // show errors
    this.dom.formEl.classList.add(this.data.formValidationClass);

    this.dom.formEl.dispatchEvent(new CustomEvent(this.customEvents.error, {
      detail: {
        message: this.data.message,
        errors: this.data.errors,
      },
    }));
  }
}
