import Translations from '../../json/translations.json';
import { lang } from './locales';

/**
 * @param langCode
 * @return {{}}
 */
export const getTranslations = (langCode) => Translations[langCode] ?? {};

/**
 * @param text {string}
 * @param placeholders {{}}
 */
const replacePlaceholders = (text, placeholders) => {
  let result = text; // Set the text as the result

  Object.entries(placeholders).forEach(([placeholder, value]) => {
    const key = `#${placeholder.trim().toUpperCase()}#`;

    result = result.replace(key, value); // Update the result with the placeholder
  });

  return result;
};

/**
 * @param key
 * @param placeholders {{}}
 * @return {string|null}
 */
export const getTranslation = (key, placeholders = {}) => {
  const text = getTranslations(lang)[key] ?? null;

  if (!text) {
    return null;
  }

  return replacePlaceholders(text, placeholders);
};

export default {
  getTranslations,
  getTranslation,
};
